* {
  box-sizing: border-box;
}

body {

  margin: 0;
  background-color: #23252C;
  font-family: Inter, sans-serif;
  padding-left: 1px;
  display: flex;
  justify-content: center;
  position: relative;
}

.header, .footer {
  background-color: #21252C;
  color: #ccc;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.header-login, .footer-login {
  background-color: #21252C;
  color: #ccc;
  width: 100%;
  max-width: 390px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.img-grid{
  width: 70px;
  margin: 10px;
  border-radius: 10px;
}

@media (min-width:768px){
  .img-grid{
    width: 150px;
  }
}
@media (min-width:1024px){
  .img-grid{
    width: 175px;
  }
}
/* Tablet styles */
@media (min-width: 768px) {
  .header, .footer {
    /* tablet-specific styles for .header */
    background-color: #21252C;
    width:100%;
    min-width: 768px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }
}
/* Desktop styles*/
@media (min-width: 1024px) {
  .header, .footer  {
    /* desktop-specific styles for .header */
    background-color: #21252C;
    width:100%;
    min-width: 1024px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }
}

.homepage-imagescroll-container{
  display: flex;
  justify-content: center;
}

.header-left, .header-right {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust gap between items as needed */
}
.header.dark-mode {
  background-color: #21252C; /* Dark mode color */
  color: #ccc;
}
.footer.dark-mode {
  background-color: #21252C; /* Dark mode color */
  color: #ccc;
}
.header-buttons{
 align-self: center; 
 margin-right: 5px;
 margin-left: 5px;
}
.header-email-input,
.header-password-input{
  max-width: 100px;
  margin-right: 5PX;
}
/*start */
.box-scroll-images {
  display: flex;
  background-color: #0056b3;
  width: 600px;
  height: 200px;
  align-items: center;
}
.scroll-image-container{
  position: relative;
  display: inline-block; /* Allows for horizontal scroll */
  margin-right: 10px;
}
.heart-icon {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  cursor: pointer;
  color: rgb(244, 235, 235); /* Default color */
  font-size: 16px; /* Adjust size as needed */
}
.heart-icon-modal {
  position: absolute;
  top: 15px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  cursor: pointer;
  color: rgb(253, 243, 243); /* Default color */
  font-size: 25px; /* Adjust size as needed */
  z-index: 1010; /* Ensure it's above the modal image */
}

.heart-icon-modal.favorited {
  color: rgb(201, 60, 60); /* Color when favorited */
}

.heart-icon.favorited {
  color: red; /* Color when favorited */
}
/*end*/
.modal{
  position: fixed; /* Fixed position to cover the whole screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex; /* Use flexbox for centering */
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  z-index: 1000; /* Ensure it's above other content */
}
.modal-content{
  position: relative;
}
.modal-image{
  max-width: 600px;
  min-width: 600px;
  height: auto;
  width: auto;
}

.close-modal {
  position: absolute;
  top: 15px;
  right: 0;
  font-size: 25px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1); /* Optional: Add background */
  border-radius: 50%; /* Optional: Round the button */
}
/* Style for the description input box */
.description-input {
  display: block; /* Makes the input take the full width of its container */
  margin: 10px 0; /* Adds some space around the input */
  padding: 8px; /* Adds some padding inside the input box */
  border: 1px solid #ccc; /* Adds a border to the input */
  border-radius: 4px; /* Rounds the corners of the input box */
  width: calc(100% - 20px); /* Sets the width to full container width minus padding */
  max-width: 150px; /* Optional: Sets a maximum width for larger screens */
  font-size: 8px;
}
.coral-name-input{
  display: block; /* Makes the input take the full width of its container */
  margin: 10px 0; /* Adds some space around the input */
  padding: 8px; /* Adds some padding inside the input box */
  border: 1px solid #ccc; /* Adds a border to the input */
  border-radius: 4px; /* Rounds the corners of the input box */
  width: calc(100% - 20px); /* Sets the width to full container width minus padding */
  max-width: 150px; 
  font-size: 8px;
}
/* Style for the upload button to align it with the input box */
.upload-btn {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.upload-btn:hover {
  background-color: #0056b3;
}

.modal-content {
    max-width: 600px; /* Adjust this value as needed */
    margin: auto;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    max-height: 90vh; /* Maximum height */
    text-align: center;
    overflow-y: auto; /* Make it scrollable */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.modal-image-container img {
  width: 100%; /* Image takes full width of its container */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove any default inline spacing */
}
.modal-info-table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}
.coral-name-cell p {
  white-space: nowrap; /* Prevent the label from wrapping */
  width: 96px; /* Adjust the width of the label */
  color: #D1D5DB;
}

.modal-edit-coral-name-input {
  width: 100px; /* Set a specific width for the input */
  padding: 2px; /* Smaller padding for a smaller input field */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Include padding and border in the width */
  /* Add any additional styles to differentiate from the main page input */
}
.coral-name-cell {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the space between label and input */
}
.coral-name-label, .coral-name-value {
  padding-top: 20px;
  color: #D1D5DB;
}
.coral-name-label{
  width: 96px;
}
.last-edited-cell {
  text-align: center;
  justify-content: center;
  font-size: xx-small;
  color: #D1D5DB;
}
.modal-description-cell {
    background-color: rgb(190, 190, 190); /* Set the background to light gray */
    color: rgb(32, 29, 29); /* Set the text color to dark gray */
    padding: 10px;
    border-top: 1px solid #ccc; /* You can keep or remove the border as per your design */
    text-align: left;
}
.modal-image-container, .modal-edit-description-input {
  max-width: 600px; /* Set this to match the maximum width of the image */
  width: 100%; /* Use 100% width to be responsive */
  margin: auto; /* Center align */
}

.modal-edit-description-input {
  box-sizing: border-box; /* Include padding and border in the width and height */
  min-height: 100px; /* Set a minimum height */
  padding: 8px; /* Padding inside the textarea */
  border: 1px solid #ccc; /* Border for the textarea */
  border-radius: 4px; /* Rounded corners */
  resize: vertical; /* Allow vertical resizing only */
}

.modal-buttons-cell {
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 8px; /* Space between buttons */
}

.last-edited-info{
  flex: 1;
  padding: 20px;
  max-width: 450px; /* Adjust the max-width as needed */
  text-align: center;
  word-wrap: break-word;
  background-color: #23252C;
}
.coral-name-input, .description-input {
  width: 100%; /* Adjust width as needed */
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.description-input {
  display: block;
  margin-top: 10px;
  /* Add more specific styles for textarea */
}


.box {
  background-color: #374151;
  width: 390px;
  height: 868px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.toggler--slider-white{
  width: 25px;
  height: 13px;
  background-color: #2B283A;
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid black;
  box-sizing: content-box;
}

.toggler--slider-black{
  width: 25px;
  height: 13px;
  background-color: white;
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid black;
  box-sizing: content-box;
}

.toggler--slider--circle-black{
  height: 13px;
  width: 13px;
  background-color: white;
  border-radius: 50%;
}

.toggler--slider--circle-white{
  height: 13px;
  width: 13px;
  background-color: black;
  border-radius: 50%;
}

.child-top-box {
  background-image: url('./images/dm-profile.png');
  background-position: top,left;
  background-repeat: no-repeat;
  background-color: #F5F5F5;
  width: 317px;
  height: 317px;
  position: absolute;
  left: 50%;
  top: 44px;
  transform: translateX(-50%);
}

.child-top-box2 {
  background-image: url('./images/dm-profile.png');
  background-position: top,left;
  background-repeat: no-repeat;
  background-color: #F5F5F5;
  width: 317px;
  height: 317px;
  position: absolute;
  left: 50%;
  top: 44px;
  transform: translateX(-50%);
}

.child-mid-box2 {
  background-color: #F5F5F5;
  width: 317px;
  height: 399px;
  position: absolute; 
  left: 50%;
  transform: translateX(-50%);
  top: 361px;
  padding-top: 21px;
  align-items: center;
  display: flex;
  flex-direction: column; /* Align children in a column */
}

.child-mid-box3 {
  background-color: #F5F5F5;
  width: 317px;
  height: 399px;
  justify-content: center;
  position: relative; 
  left: 107px;
  top: 361px;
  padding-top: 21px;
  align-items: center;
  display: flex;
  flex-direction: column; /* Align children in a column */
}

.label-box1{
  align-self: center;
  height: 26px;
  width: 178px;
  background-color: #23252C;
}

.label-box1-option2{
  align-self: center;
  height: 26px;
  width: 178px;
  background-color: #F5F5F5;
}

.label-box1-option3{
  align-self: center;
  height: 26px;
  width: 178px;
  background-color: #1A1B21;
}

.midbox-header-title{
  color: #ffffff;
  font-family: "Inter-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.midbox-header-title2{
  color: #2B283A;
  font-family: "Inter-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.midbox-header-title3{
  color: #ffffff;
  font-family: "Inter-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.img-firebase{
  width: 150px;
  margin: 10px;
}


.main-page{
  background-color: #effcfe;
  color: black;
  display: grid;
}
.main-page.dark-mode{
  background-color: #23252C;
  color: #F5F5F5;
  display: grid;
}

.img-label{
  max-width: 70px;
}

.modal-backdrop {
  background-color: #23252C;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}
.modal-coralName {
  background-color: #23252C;
  flex: 1;
  padding: 5px;
  max-width: 250px; /* Adjust the max-width as needed */
  text-align: center;
  font-size: large;
}
.modal-content {
  background-color: #23252C;
  padding: 20px;
  border-radius: 5px;
  max-width: 600px;
  text-align: center;
}

.modal-content img {
  background-color: #23252C;
  max-width: 90vw;
  max-height: 90vh;
}

.images-list {
  background-color: #effcfe;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns as needed */
  grid-gap: 10px; /* Spacing between items */
}
.images-list.dark-mode{
  background-color: #404142;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns as needed */
  grid-gap: 10px; /* Spacing between items */
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the image and label */
  cursor: pointer;  
}
.pagination{
  margin-top: 20px;
  padding-left: 20px;
}

.test-h1{
  color: black;
  font-size: 18px;
}

.label-box2{
  height: 14px;
  width: 178px;
  background-color: #23252C;
  margin-top: 5px;
}

.label-box2-option2{
  height: 14px;
  width: 178px;
  background-color: #F5F5F5;
  margin-top: 6.19px;
}

.label-box2-option3{
  height: 14px;
  width: 178px;
  background-color: #1A1B21;
  margin-top: 6.19px;
}

.label-box2-option4{
  height: 14px;
  width: 178px;
  background-color: #F5F5F5;
  margin-top: 6.19px;
  margin-bottom: 6.19px;
}

.frontend-developer{
	height: 18.5px;
	font-family: Inter;
	font-size: 13px;
	text-align: center;
	color:#F3BE99;
  font-weight: bold;
}

.frontend-developer2{
	height: 18.5px;
	font-family: Inter;
	font-size: 13px;
	text-align: center;
	color:#F47D27;
  font-weight: bold;
}

.frontend-developer3{
	color: #F47D27;
  text-align: center;
  font-family: Inter;
  font-size: 12.8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 6.19px;
  margin-bottom: 8.81px;
}

.frontend-developer4{
	color: #F47D27;
  text-align: center;
  font-family: Inter;
  font-size: 12.8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 6.19px;
  margin-bottom: 1px;
}

.email-link-box{
	height: 19px;
	font-family: Inter;
	font-size: 10.24px;
	text-align: center;
	line-height: 15.36px;
  background-color:#23252C;
  width: 178px;
  margin-top: 8.81px;
  
}
.email-link-box2{
	height: 19px;
	font-family: Inter;
	font-size: 10.24px;
	text-align: center;
	line-height: 15.36px;
  background-color:#F5F5F5;
  width: 178px;
  margin-top: 8.81px;
}
.email-link-box3{
	height: 19px;
	font-family: Inter;
	font-size: 10.24px;
	text-align: center;
	line-height: 15.36px;
  background-color:#1A1B21;
  width: 178px;
  margin-top: 8.81px;
}
.email-link-box4{
	height: 19px;
	font-family: Inter;
	font-size: 10.24px;
	text-align: center;
	line-height: 15.36px;
  background-color:#F5F5F5;
  width: 178px;
  margin-top: 8.81px;
  
}
.email1{
  color: #FFFFFF;
  text-decoration: none;
}
.email2{
  color: #4A4E74;
}
.email3{
  color: #FFFFFF;
}
.email4{
  color: #4A4E74;
  text-align: center;
  font-family: Inter;
  font-size: 10.24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 15.36px */
  text-decoration-line: underline;;
}
.mail-icon{
  height: 16px;
  position: relative;
  width: 16px;
  color: #1A1B21;
}
.mail-icon2{
  height: 16px;
  position: relative;
  width: 16px;
  color: #1E1F26;
}
.mail-icon3{
  height: 16px;
  position: relative;
  width: 16px;
  color: #1E1F26;
}
.buttons-box{
    display: flex;
    position: relative;
    background-color: #23252C;
}
.buttons-box2{
  display: flex;
  position: relative;
  background-color: #F5F5F5;
}
.buttons-box4{
  display: flex;
  position: relative;
  background-color: #F5F5F5;
}
.button-email3{
  display: flex;
  position: relative;
  background-color: #FFFFFF;
  display: flex;
  width: 247px;
  height: 34px;
  padding: 9px 13px 9px 11px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #D1D5DB);
  /* shadow/sm */
  box-shadow: 0px 1px 2px 2px rgba(98, 89, 89, 0.92);
  margin-top: 15px;
  cursor: pointer;
}
.button-email4{
  display: flex;
  position: relative;
  background-color: #FFFFFF;
  display: flex;
  width: 247px;
  height: 34px;
  padding: 9px 13px 9px 11px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #D1D5DB);
  /* shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  margin-top: 15px;
  cursor: pointer;
}


.button-email{
  align-items: center;
  background-color:#D1D5DB;
  border-color: var(--gray-300);
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 9px 13px 9px 11px;
  width: 115px;
  margin-right: 17px;
  margin-top: 15px;
  cursor: pointer;
}
.button-email2{
  align-items: center;
  background-color:#FFFFFF;
  border-color: var(--gray-300);
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 9px 13px 9px 11px;
  width: 115px;
  margin-right: 17px;
  margin-top: 15px;
  border-radius: 6px;
  background: var(--white, #FFF);
/* shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.button-text3{
  color: var(--gray-700, #374151);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  
}

.button-linkedin{
  align-items: center;
  background-color: #4f93e2;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  padding: 9px 13px 9px 11px;
  position: relative;
  width: 115px;
  justify-content: center;
  margin-top: 15px;
  text-decoration: none;
  cursor: pointer;
}
.button-linkedin2{
  align-items: center;
  background-color: #4f93e2;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  padding: 9px 13px 9px 11px;
  position: relative;
  width: 115px;
  justify-content: center;
  margin-top: 15px;
  cursor: pointer;
}
.button-linkedin-text{
  color: #ffffff;
  font-family: "Inter-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  top: 0;
  white-space: nowrap;
  text-decoration: none;
}
.button-linkedin-text2{
  color: #ffffff;
  font-family: "Inter-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  top: 0;
  white-space: nowrap;
  
}

.midbox-about-title{
	width: 257px;
	height: 25.6px;
	font-family: Inter;
	font-size: 18px;
	font-weight: bold;
	text-align: left;
	line-height: 24px;
	color:#F5F5F5;
  margin-top: 25px;
}
.midbox-about-title2{
	width: 257px;
	height: 25.6px;
	font-family: Inter;
	font-size: 18px;
	font-weight: bold;
	text-align: left;
	line-height: 24px;
	color:#2B283A;
  margin-top: 25px;
}
.midbox-about-text{
  top: 561px;
	left: 143px;
	width: 258px;
	height: 64px;
	font-family: Inter;
	font-size: 12.4px;
	text-align: left;
	line-height: 15.36px;
	color:#DCDCDC;
  margin-top: 5px;
}
.midbox-about-text2{
  top: 561px;
	left: 143px;
	width: 258px;
	height: 64px;
	font-family: Inter;
	font-size: 12.4px;
	text-align: left;
	line-height: 15.36px;
	color:#4A4E74;
  margin-top: 5px;
}
.midbox-interests-title{
  top: 649px;
	left: 143px;
	width: 257px;
	height: 25.6px;
	font-family: Inter;
	font-size: 18px;
	font-weight: bold;
	text-align: left;
	line-height: 24px;
	color:#F5F5F5;
  margin-top: 27px;
}
.midbox-interests-title2{
  top: 649px;
	left: 143px;
	width: 257px;
	height: 25.6px;
	font-family: Inter;
	font-size: 18px;
	font-weight: bold;
	text-align: left;
	line-height: 24px;
  margin-top: 27px;
  color: #2B283A;
  font-style: normal;
}
.midbox-interests-text{
  top: 675px;
	left: 143px;
	width: 258px;
	height: 64px;
	overflow: hidden;
	font-family: Inter;
	font-size: 12.4px;
	text-align: left;
	line-height: 15.36px;
	color:#DBDBDC;  
  margin-top: 5px;
  margin-bottom: 31px;
}
.midbox-interests-text2{
  top: 675px;
	left: 143px;
	width: 258px;
	height: 64px;
	font-family: Inter;
	font-size: 12.4px;
	text-align: left;
	line-height: 15.36px;
	color:#4A4E74;  
  margin-top: 5px;
  font-style: normal;
  margin-bottom: 31px;
}

.HeaderTitle{
  color: #FFFFFF;
}
.child-mid-box {
  background-color: #23252C;
  width: 317px;
  height: 399px;
  position: absolute; /* Change from relative to absolute */
  left: 50%; /* Center horizontally relative to .box */
  transform: translateX(-50%); /* Shift to the left by half its own width */
  top: 361px; /* Adjust the top position as needed */
  padding-top: 21px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.child-bottom-box {
  background-color: #161619;
  width: 317px;
  height: 64px;
  position: absolute; /* Ensure it's positioned absolutely within .box */
  left: 50%; /* Center horizontally relative to .box */
  transform: translateX(-50%); /* Shift left by half its own width */
  top: 760px; /* Adjust top as needed */
  display: flex;
  justify-content: center; /* This centers the children of .child-bottom-box horizontally */
}

.child-bottom-box2 {
  background-color: #D5D4D8;
  width: 317px;
  height: 64px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 760px;
  display: flex;
  justify-content: center;
}
.bottom-icons{
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
}
.bottom-icons2{
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
}
.bottom-icons img {
  
  width: 25px;
  height: 25px;
}
.email-button{
  color: #1A1B21;
  
}
.email-button,
.button-linkedin a {
text-decoration: none;
}
.main-row{
  display: flex;
  gap: 2rem;
}
.main-col{
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.box{
  margin-bottom: 2rem;
}